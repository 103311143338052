import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../common/withRouter";

import Grid from "../common/GridWrapper";
import Typography from "../common/TypographyWrapper";

import AppContainer from "../common/AppContainer";

class DelegateResponse extends Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        var type = this.props.match.params["type"];
        return (
            <AppContainer>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={10} md={6}>
                        <Typography variant="title" gutterBottom>
                            Field to Market - Fieldprint Platform Version 3 Notification
                        </Typography>
                        {type === "accept" && (
                            <Typography gutterBottom>
                                You have confirmed delegate access authority. Once logged into the Fieldprint Platform,
                                you will see an option for Delegate Access in User Options (menu at the top right of the
                                page once logged in). From the Delegate Access page, you will find an option to select
                                the user and begin entering data on their behalf. Click here to access the FIeldprint
                                Platform login page.
                            </Typography>
                        )}
                        {type === "reject" && (
                            <Typography gutterBottom>You have rejected this delegate access request.</Typography>
                        )}
                        <Typography gutterBottom>
                            <Link style={{ color: "#808080" }} to="/">
                                Return to Home Page
                            </Link>{" "}
                        </Typography>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

export default withRouter(DelegateResponse);
