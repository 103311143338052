import React from "react";
import Score from "./Score";
import { round } from "../StaticData";

export default function SoilCarbonScore({ indicator, printable }) {
    const value = indicator.fieldprintResult.value,
        label =
            !value && value !== 0
                ? "Unknown"
                : value < -0.05
                  ? "Depleting"
                  : value <= 0.05
                    ? "Maintaining"
                    : "Increasing";
    return <Score value={round(value, indicator.metric)} unit={`(${label})`} printable={printable} />;
}
