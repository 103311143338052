import orm, { ReferenceData } from "../../common/orm";

export class FarmHPIReferenceData extends ReferenceData {
    static get source() {
        throw new Error("Static data");
        // return `/v4/calc/FarmHPI/ReferenceData/${this.modelName}`;
    }
}

export class CoverCrop extends FarmHPIReferenceData {
    static get modelName() {
        return "CoverCrop";
    }
}
orm.register(CoverCrop);

export class NoncultivatedLandTypes extends FarmHPIReferenceData {
    static get modelName() {
        return "NoncultivatedLandTypes";
    }
}
orm.register(NoncultivatedLandTypes);

export class RiparianVegetation extends FarmHPIReferenceData {
    static get modelName() {
        return "RiparianVegetation";
    }
}
orm.register(RiparianVegetation);

export class RiparianManagement extends FarmHPIReferenceData {
    static get modelName() {
        return "RiparianManagement";
    }
}
orm.register(RiparianManagement);

export class ForestConfiguration extends FarmHPIReferenceData {
    static get modelName() {
        return "ForestConfiguration";
    }
}
orm.register(ForestConfiguration);

export class ForestManagement extends FarmHPIReferenceData {
    static get modelName() {
        return "ForestManagement";
    }
}
orm.register(ForestManagement);

export class GrasslandConfiguration extends FarmHPIReferenceData {
    static get modelName() {
        return "GrasslandConfiguration";
    }
}
orm.register(GrasslandConfiguration);

export class GrazingIntensity extends FarmHPIReferenceData {
    static get modelName() {
        return "GrazingIntensity";
    }
}
orm.register(GrazingIntensity);

export class GrasslandManagement extends FarmHPIReferenceData {
    static get modelName() {
        return "GrasslandManagement";
    }
}
orm.register(GrasslandManagement);

export class NaturalWetlandRegime extends FarmHPIReferenceData {
    static get modelName() {
        return "NaturalWetlandRegime";
    }
}
orm.register(NaturalWetlandRegime);

export class WetlandManagement extends FarmHPIReferenceData {
    static get modelName() {
        return "WetlandManagement";
    }
}
orm.register(WetlandManagement);

export class SurfaceWaterBufferPercentage extends FarmHPIReferenceData {
    static get modelName() {
        return "SurfaceWaterBufferPercentage";
    }
}
orm.register(SurfaceWaterBufferPercentage);

export class SurfaceWaterRiparianVegetation extends FarmHPIReferenceData {
    static get modelName() {
        return "SurfaceWaterRiparianVegetation";
    }
}
orm.register(SurfaceWaterRiparianVegetation);

export class BankStability extends FarmHPIReferenceData {
    static get modelName() {
        return "BankStability";
    }
}
orm.register(BankStability);

export class BankManagement extends FarmHPIReferenceData {
    static get modelName() {
        return "BankManagement";
    }
}
orm.register(BankManagement);

export class SurfaceWaterChannelization extends FarmHPIReferenceData {
    static get modelName() {
        return "SurfaceWaterChannelization";
    }
}
orm.register(SurfaceWaterChannelization);

export class SurfaceWaterInstreamHabitat extends FarmHPIReferenceData {
    static get modelName() {
        return "SurfaceWaterInstreamHabitat";
    }
}
orm.register(SurfaceWaterInstreamHabitat);

export class SurfaceWaterPredominantUse extends FarmHPIReferenceData {
    static get modelName() {
        return "SurfaceWaterPredominantUse";
    }
}
orm.register(SurfaceWaterPredominantUse);

export class SurfaceWaterAverageDepth extends FarmHPIReferenceData {
    static get modelName() {
        return "SurfaceWaterAverageDepth";
    }
}
orm.register(SurfaceWaterAverageDepth);

export class SurfaceWaterManagement extends FarmHPIReferenceData {
    static get modelName() {
        return "SurfaceWaterManagement";
    }
}
orm.register(SurfaceWaterManagement);
