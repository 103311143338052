import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../common/withRouter";

import Grid from "../common/GridWrapper";
import Typography from "../common/TypographyWrapper";

import AppContainer from "../common/AppContainer";

class RegisterPage extends Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        var email = this.props.match.params["email"];
        return (
            <AppContainer>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={10} md={6}>
                        <Typography variant="title" gutterBottom>
                            Registration Complete
                        </Typography>
                        <Typography gutterBottom>
                            Your account with the username <b>{email}</b> has been created. Before you can log in, you
                            must activate your account. Please check your email for a link for activation.
                        </Typography>
                        <Typography gutterBottom>
                            If you did not receive your activation email, check your spam or junk folder for an email
                            from <b>noreply@fieldtomarket.org</b>. It is also possible there was a typo or error with
                            the email address you entered. Please double check the email above to makes sure it is
                            correct. Please contact{" "}
                            <a style={{ color: "#808080" }} href="mailto:support@fieldtomarket.org">
                                support@fieldtomarket.org
                            </a>{" "}
                            if you need assistance.
                        </Typography>
                        <Typography gutterBottom>
                            <Link style={{ color: "#808080" }} to="/">
                                Return to Home Page
                            </Link>{" "}
                        </Typography>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

export default withRouter(RegisterPage);
